import { colors, dashboardSurveyDict } from 'configs'
import { Avatar, Column, Row, Tooltip, Typography } from 'design-system'

import styled from 'styled-components'
import { useMemo } from 'react'
import { ratingRangeReduce } from 'helpers'
import CardSurveyEmoji from 'components/specific/dashboard/CardSurveyEmoji'

const Progress = styled(Row)`
  width: 0;
  height: 8px;
  border-radius: 20px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  overflow: hidden;
  animation-delay: ${({ delay }) => delay};
`

const ProgressLine = styled(Row)`
  width: ${({ percent }) => (percent ? '100%' : '0%')};
`

const ProgressSurvey = ({ user, selectedUser, index }) => {
  const [, setSelectedUser] = selectedUser || []

  const { company_user, rates } = user

  const goodOrGreat = useMemo(() => ratingRangeReduce(rates, [4, 5]), [rates])

  return (
    <Column width='100%' p='8px'>
      {selectedUser && (
        <Row width='100%' alignItems='center' justifyContent='space-between'>
          <Row
            alignItems='center'
            gap='8px'
            className='cursor-pointer'
            onClick={() => setSelectedUser(user)}
          >
            {company_user && <Avatar src={company_user?.picture} alt={company_user?.name} />}
            <Typography fontSize='14px' color={company_user?.name ? 'blue.80' : 'grey.300'}>
              {company_user?.name || 'Sem atendente responsável'}
            </Typography>
          </Row>
          <Typography>{`${goodOrGreat || 0}%`}</Typography>
        </Row>
      )}
      <Progress
        mt='8px'
        backgroundColor={colors.grey[25]}
        className='animation-grow-element'
        delay={`0.${index}s`}
      >
        {rates?.map(({ rate, percent, amount }, idx) => {
          const { color, label, emoji } = dashboardSurveyDict[rate?.value]

          return (
            <Tooltip
              bgColor={colors.white}
              containerProps={{
                width: `${percent}%`,
                backgroundColor: 'transparent',
                borderRadius: idx === rates.length - 1 ? '0 20px 20px 0' : '0',
              }}
              title={
                <CardSurveyEmoji
                  bgColor='#fff'
                  label={label}
                  emoji={emoji}
                  percent={`${percent}%`}
                  value={amount}
                  color={color}
                />
              }
            >
              <ProgressLine
                percent={`${percent}%`}
                backgroundColor={color}
                borderRadius={idx === rates.length - 1 ? '0 20px 20px 0' : '0'}
              />
            </Tooltip>
          )
        })}
      </Progress>
    </Column>
  )
}

export default ProgressSurvey
