import { useEffect, useState } from 'react'

import { Column, Row } from 'design-system'
import TicketHeader from 'components/specific/tickets/TicketHeader'
import TicketMessage from 'components/specific/tickets/TicketMessage'
import TicketContactDrawer from 'components/specific/tickets/TicketContactDrawer'

import { formatDateLabel, notify } from 'helpers'

import { markMessageRead, showTicketInfo, showTicketMessagesList, updateTicket } from 'services'
import CloseTicketHeader from './CloseTicketHeader'
import { ModalAcceptTicket } from 'components'
import SkeletonMessages from './SkeletonMessages'

import { useTickets } from 'hooks'
import CustomMessage from './CustomMessage'
import { format } from 'date-fns'
import TicketMessageInput from 'components/specific/tickets/TicketMessageInput'
import DateSeparator from './DateSeparator'

const TicketContainer = ({ ticketId }) => {
  const [loading, setLoading] = useState(false)
  const [openDrawer, setOpenDrawer] = useState(true)
  const [openCloseOption, setOpenCloseOption] = useState(false)
  const [acceptTicket, setAcceptTicket] = useState(false)

  const { state, dispatch, actionMessagesTypes, actionSelectedTicketTypes, actionTicketTypes } =
    useTickets()

  useEffect(() => {
    ticketId && fetchTicket(ticketId)
    setOpenCloseOption(false)

    return () => dispatch({ type: actionMessagesTypes.RESET_MESSAGES })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketId])

  const fetchTicket = async (id) => {
    try {
      setLoading(true)

      const [{ data: ticket }, { data: messages }] = await Promise.all([
        showTicketInfo(id),
        showTicketMessagesList(id, { page_size: 1000 }),
      ])

      const { data } = await markMessageRead(id)

      const groupedMessages = messages.results.reduce((acc, curr) => {
        const key = format(curr.created_at, 'dd/MM/yyyy')

        acc[key] ? acc[key].push(curr) : (acc[key] = [{ ...curr, key }])

        return acc
      }, {})

      data?.ticket_data.status === state.current_status &&
        dispatch({
          type: actionTicketTypes.UPDATE_TICKET,
          payload: data?.ticket_data,
        })

      dispatch({
        type: actionMessagesTypes.LOAD_MESSAGES,
        payload: groupedMessages,
      })

      dispatch({
        type: actionSelectedTicketTypes.LOAD_SELECTED_TICKET,
        payload: ticket,
      })
    } catch {
      notify.error('Não foi possível carregar mensagens do contato.')
    } finally {
      setLoading(false)
    }
  }

  const handlerUpdateTicket = async (id, values) => {
    try {
      setLoading(true)

      const { data } = await updateTicket(id, values)

      dispatch({ type: actionSelectedTicketTypes.UPDATE_SELECTED_TICKET, payload: data })

      values.send_satisfaction_survey
        ? notify.success(
            'Conversa encerrada e pesquisa de satisfação enviada ao contato com sucesso!',
          )
        : notify.success('Conversa encerrada com sucesso!')

      setOpenCloseOption(false)
    } catch {
      notify.error('Não foi possível atualizar conversa.')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Row width='100%'>
      <Column position='relative' width='100%'>
        <TicketHeader
          loading={loading}
          ticket={state?.selected_ticket}
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          openCloseOption={openCloseOption}
          setOpenCloseOption={setOpenCloseOption}
          acceptTicket={acceptTicket}
          setAcceptTicket={setAcceptTicket}
        />
        {loading ? (
          <SkeletonMessages />
        ) : (
          <Column
            background='#FAFAFB'
            borderTop='none'
            alignItems='center'
            width='100%'
            height='100%'
            overflow='hidden'
          >
            {openCloseOption && (
              <CloseTicketHeader
                ticket={state.selected_ticket}
                setOpenCloseOption={setOpenCloseOption}
                handlerUpdateTicket={handlerUpdateTicket}
                loading={loading}
              />
            )}
            <Column width='100%' flexDirection='column-reverse' height='80%' overflow='auto'>
              {Object.keys(state.messages)
                .sort((itemA, itemB) => formatDateLabel(itemB) - formatDateLabel(itemA))
                .map((date, idx) => (
                  <Column key={`${date}_${idx}`} position='relative' p='10px 10px 0'>
                    {state?.selected_ticket?.closed_at && (
                      <CustomMessage
                        message={{
                          text: `*Conversa foi finalizada*\n${format(
                            state?.selected_ticket?.closed_at,
                            'dd/MM/yyyy - HH:mm',
                          )}`,
                          message_type: 'text',
                          sender_type: 'internal',
                        }}
                      />
                    )}
                    <Column flexDirection='column-reverse' gap='8px'>
                      {state.messages[date].map((message, index) => (
                        <TicketMessage
                          key={`${message?.id}_${index}`}
                          index={index}
                          message={message}
                        />
                      ))}
                      <DateSeparator date={date} />
                    </Column>
                  </Column>
                ))}
            </Column>
            <TicketMessageInput ticket={state.selected_ticket} setAcceptTicket={setAcceptTicket} />
          </Column>
        )}
      </Column>
      {openDrawer && (
        <TicketContactDrawer
          ticket={state.selected_ticket}
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          loading={loading}
        />
      )}
      {acceptTicket && (
        <ModalAcceptTicket
          loading={loading}
          setLoading={setLoading}
          ticket={acceptTicket}
          open={acceptTicket}
          dispatch={dispatch}
          actionTypes={actionSelectedTicketTypes}
          onClose={() => setAcceptTicket(false)}
        />
      )}
    </Row>
  )
}

export default TicketContainer
