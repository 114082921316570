import PreviewTemplateMessage from 'components/common/PreviewTemplateMessage'

import { Modal, ModalBody, ModalHeader, Button, Typography, ModalFooter } from 'design-system'

const ModalSendSurvey = ({ open, onClose, loading, callBack, ticketId, params, ...props }) => {
  const message = 'Clique abaixo e escolha a opção que melhor descreve nosso atendimento.'

  return (
    <Modal size='sm' open={open} onClose={onClose} {...props}>
      <ModalHeader onClose={onClose}>
        <Typography variant='title' color='primary'>
          Pesquisa de satisfação
        </Typography>
      </ModalHeader>
      <ModalBody>
        <Typography fontSize='18px' fontWeight='600' color='grey.400'>
          Deseja enviar uma pesquisa de satisfação para o cliente?
        </Typography>
        <Typography mt='8px' color='grey.400'>
          Esta pesquisa ajuda a entender a qualidade do atendimento prestado e melhorar a
          experiência dos nossos clientes.
        </Typography>
        <PreviewTemplateMessage mt='8px' message={message} icon='Share' />
      </ModalBody>
      <ModalFooter>
        <Button
          width='fit-content'
          variant='text'
          color='grey.500'
          onClick={() => callBack(ticketId, { ...params, send_satisfaction_survey: false })}
        >
          Não enviar pesquisa
        </Button>
        <Button
          width='fit-content'
          onClick={() => callBack(ticketId, { ...params, send_satisfaction_survey: true })}
          disabled={loading}
        >
          Enviar pesquisa
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ModalSendSurvey
