import { colors } from 'configs'
import { Column, Image, Row, Typography } from 'design-system'

const CardSurveyEmoji = ({ label, percent, value, emoji, color, bgColor }) => {
  return (
    <Column
      justifyContent='center'
      alignItems='center'
      p='8px'
      background={bgColor}
      borderRadius='8px'
      minWidth='96px'
    >
      <Row alignItems='center' gap='8px'>
        {emoji && <Image src={emoji} width='16px' height='16px' />}
        <Typography>{label}</Typography>
      </Row>
      <Row gap='4px' alignItems='center'>
        <Typography fontWeight='600' color={color}>
          {percent}
        </Typography>
        <Typography variant='caption'>{value}</Typography>
      </Row>
    </Column>
  )
}

CardSurveyEmoji.defaultProps = {
  label: 'Sem resposta',
  percent: '0%',
  value: '0',
  bgColor: colors.grey[25],
}

export default CardSurveyEmoji
