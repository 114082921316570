import CardSurveyEmoji from 'components/specific/dashboard/CardSurveyEmoji'
import CardValue from 'components/specific/dashboard/CardValue'
import ProgressSurvey from 'components/specific/dashboard/ProgressSurvey'
import { dashboardSurveyDict } from 'configs'
import { Card, Column, Row, Typography } from 'design-system'
import { ratingRangeReduce } from 'helpers'
import { useMemo } from 'react'

const UserRatingDetails = ({ user }) => {
  const { total_tickets, unrated_percent, rated_amount, rates, unrated_amount } = user

  const rated_percent = useMemo(
    () => (100 - unrated_percent).toString().padStart(2),
    [unrated_percent],
  )

  const goodOrGreat = useMemo(() => ratingRangeReduce(rates, [4, 5]), [rates])

  const badOrTerrible = useMemo(() => ratingRangeReduce(rates, [1, 2]), [rates])

  return (
    <Column gap='16px'>
      <Row gap='24px'>
        <CardValue title='Total de conversas' value={total_tickets} backgroundColor='grey.40' />
        <CardValue
          title='Conversas avaliadas'
          value={rated_amount}
          description={`${Number(rated_percent || 0).toFixed(2)}%`}
          backgroundColor='grey.40'
        />
        <CardValue
          title='Bom e ótimo'
          value={`${goodOrGreat}%`}
          backgroundColor='grey.40'
          labelProps={{ color: 'green.300' }}
        />
        <CardValue
          title='Ruim e péssimo'
          value={`${badOrTerrible}%`}
          backgroundColor='grey.40'
          labelProps={{ color: 'red.300' }}
        />
      </Row>
      <Card backgroundColor='grey.40' alignItems='center'>
        <Typography fontWeight='600' textAlign='center'>
          Avaliação geral das conversas
        </Typography>
        <ProgressSurvey user={user} />
        <Row mt='16px' width='100%' justifyContent='center' gap='16px'>
          <CardSurveyEmoji
            label={`Sem resposta`}
            percent={`${unrated_percent}%`}
            value={unrated_amount}
          />
          {rates?.map(({ rate, amount, percent }) => {
            const { emoji, label, color } = dashboardSurveyDict[rate.value]
            return (
              <CardSurveyEmoji
                emoji={emoji}
                label={label}
                color={color}
                percent={`${percent}%`}
                value={amount}
              />
            )
          })}
        </Row>
      </Card>
    </Column>
  )
}

export default UserRatingDetails
