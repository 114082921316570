import { useState } from 'react'
import { Row, Icon, Typography, Button, Column } from 'design-system'
import ModalSendSurvey from 'components/modals/ModalSendSurvey'
import { hexToRGBA } from 'helpers'
import { colors } from 'configs'

const CloseTicketHeader = ({ setOpenCloseOption, handlerUpdateTicket, ticket, loading }) => {
  const [modalSurvey, setModalSurvey] = useState(null)

  const { id, connection } = ticket

  const surveyQueryMode = connection.satisfaction_survey_mode === 'query'
  const surveyAutomaticMode = connection.satisfaction_survey_mode === 'always'

  return (
    <>
      <Row
        backgroundColor={hexToRGBA(colors.black[100], 0.25)}
        width='100%'
        height='100vh'
        position='absolute'
        zIndex='1'
      />
      <Row
        position='absolute'
        backgroundColor='grey.90'
        borderRadius='0 0 8px 8px'
        zIndex='10'
        p='15px'
        width='100%'
        justifyContent='space-between'
      >
        <Column>
          <Row width='100%' gap='8px' alignItems='center'>
            <Icon icon='Information' color='grey.300' />
            <Typography color='grey.400'>Tem certeza que deseja encerrar essa conversa?</Typography>
          </Row>
          {surveyAutomaticMode && (
            <Typography
              pl='28px'
              mt='8px'
              width='300px'
              fontStyle='italic'
              fontSize='12px'
              color='grey.200'
            >
              Ao encerrar, uma pesquisa de satisfação será enviada automaticamente. Para desativar o
              envio, acesse as configurações da conexão.
            </Typography>
          )}
        </Column>
        <Row gap='16px'>
          <Button
            maxWidth='100px'
            variant='text'
            color='grey.500'
            onClick={() => setOpenCloseOption(false)}
            disabled={loading}
          >
            Cancelar
          </Button>
          <Button
            maxWidth='240px'
            color='red.300'
            onClick={() =>
              surveyQueryMode ? setModalSurvey(true) : handlerUpdateTicket(id, { status: 'closed' })
            }
            disabled={loading}
          >
            Encerrar
          </Button>
        </Row>
      </Row>

      {modalSurvey && (
        <ModalSendSurvey
          open={modalSurvey}
          onClose={() => setModalSurvey(false)}
          callBack={handlerUpdateTicket}
          ticketId={id}
          params={{ status: 'closed' }}
        />
      )}
    </>
  )
}

export default CloseTicketHeader
