import { useState } from 'react'

import { Column } from 'design-system'
import messagesTypeDict from './messagesTypeDict'
import { ModalExpandImage } from 'components'
import MessageFooter from './MessageFooter'
import { colors } from 'configs'
import { hexToRGBA } from 'helpers'
import styled from 'styled-components'

const fileStyle = {
  pdf: { padding: '0' },
  audio: { minWidth: '300px' },
  image: { width: 'fit-content' },
  video: { width: '280px' },
  other: {},
}

const messageTypeStyles = {
  text: () => ({ width: 'fit-content', maxWidth: '424px' }),
  list: () => ({ width: 'fit-content', maxWidth: '424px' }),
  file: (files) => ({
    ...fileStyle[files[0]?.file_category?.value],
  }),
  template: () => ({ width: 'fit-content', maxWidth: '424px' }),
  location: () => {},
  contact: () => {},
}

const messageFromDict = {
  attendant: ({ message_type, files }) => ({
    padding: '8px 8px 4px 8px',
    borderRadius: '8px 8px 0 8px',
    background: hexToRGBA(colors.violet[100], 0.6),
    ...(messageTypeStyles[message_type](files) || {}),
  }),
  contact: ({ message_type, files }) => ({
    padding: '8px 8px 4px 8px',
    borderRadius: '8px 8px 8px 0',
    background: colors.grey[50],
    ...(messageTypeStyles[message_type](files) || {}),
  }),
  system: ({ message_type, files }) => ({
    padding: '8px 8px 4px 8px',
    borderRadius: '8px',
    maxWidth: '250px',
    background: colors.blue[60],
    ...(messageTypeStyles[message_type](files) || {}),
  }),
  internal: () => ({
    maxWidth: '250px',
    padding: '8px 8px 4px 8px',
    borderRadius: '8px',
    background: colors.blue[60],
  }),
}

const containerDict = {
  attendant: {
    alignItems: 'flex-end',
  },
  contact: {
    alignItems: 'flex-start',
  },
  system: {
    alignItems: 'flex-end',
  },
  internal: {
    alignItems: 'center',
  },
}

const TicketMessage = ({ message, index, ...props }) => {
  const [expand, setExpand] = useState(null)

  const {
    id,
    message_type,
    sender_type,
    sent_through_whatsapp,
    created_at,
    status,
    files,
    user_sender,
  } = message || {}

  const messageFrom = sent_through_whatsapp ? 'attendant' : sender_type

  const messageInput = messagesTypeDict[message_type] || messagesTypeDict['text']

  return (
    <Column key={`${index}_${id}`} width='100%' {...containerDict[messageFrom]} {...props}>
      <CustomStyles {...messageFromDict[messageFrom]({ message_type, files })}>
        {messageInput({ message, setExpand })}
        <MessageFooter
          createdAt={created_at}
          userSender={user_sender}
          status={status}
          senderType={sender_type}
        />
        {expand && (
          <ModalExpandImage
            closeClickOut
            open={expand}
            onClose={() => setExpand(null)}
            data={expand}
          />
        )}
      </CustomStyles>
    </Column>
  )
}

const CustomStyles = styled(Column)`
  & .conversation__user_sender:first-child {
    visibility: hidden;
  }

  &:hover .conversation__user_sender:first-child {
    visibility: visible;
  }
`

export default TicketMessage
