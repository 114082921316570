import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import {
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Button,
  Typography,
  ModalFooter,
  SelectAsync,
  Column,
  Select,
  Image,
  Spinner,
} from 'design-system'

import {
  createTicket,
  showContactsListSelect,
  showDepartmentsListSelect,
  showCompanyUsersListSelect,
  showTemplatesList,
  showConnectionsListSelect,
} from 'services'
import { notify } from 'helpers'
import PreviewTemplate from 'components/specific/tickets/PreviewTemplate'
import { robotAlert } from 'assets/ilustrations'

const ModalCreateTicket = ({
  onClose,
  info,
  dispatch,
  actionTypes,
  actionCurrentStatusTypes,
  ...props
}) => {
  const [loading, setLoading] = useState(false)
  const [attendants, setAttendants] = useState([])
  const [connections, setConnections] = useState([])
  const [templatesList, setTemplatesList] = useState([])

  const navigate = useNavigate()

  const {
    handleSubmit,
    reset,
    control,
    watch,
    register,
    setValue,
    formState: { errors },
  } = useForm()

  const watchDepartment = watch('department')
  const watchConnection = watch('connection')

  useEffect(() => {
    getConnectionsOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    watchDepartment && getUserOptions(watchDepartment)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchDepartment])

  useEffect(() => {
    watchConnection?.id && handleGetTemplatesList(watchConnection.id)
  }, [watchConnection])

  useEffect(() => {
    reset({
      contact: '',
      tags: [],
      department: '',
      starter_template: '',
      starter_template_params: [],
    })

    return () => {
      reset({
        contact: '',
        tags: [],
        department: '',
        starter_template: '',
        starter_template_params: [],
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info])

  const onSubmit = async (values) => {
    try {
      setLoading(true)

      values.contact = values.contact.id
      values.attendant = values.attendant.id
      values.tags = []
      values.department = values.department.id
      values.starter_template = values.starter_template.id
      values.starter_template_params = values.params
        .map((param) => param.text)
        .filter((item) => item)

      const { data } = await createTicket(values)

      dispatch({
        type: actionTypes.ADD_TICKET,
        payload: data,
      })

      navigate(`/tickets/${data.id}/`)
      dispatch({ type: actionCurrentStatusTypes.SELECT_STATUS, payload: 'attending' })

      notify.success('Nova conversa criada com sucesso.')
      onClose()
    } catch {
      notify.error('Não foi possível criar nova conversa.')
    } finally {
      setLoading(false)
    }
  }

  const handleGetTemplatesList = async (connection) => {
    try {
      setLoading(true)
      const { data } = await showTemplatesList({
        connection,
        page_size: 100,
        status: 'APPROVED',
      })

      setTemplatesList(data.results)
    } catch {
      notify.error('Não foi possível resgatar listagem de templates.')
    } finally {
      setLoading(false)
    }
  }

  const getUserOptions = async (department) => {
    try {
      const { data } = await showCompanyUsersListSelect({
        page_size: 100,
        active: true,
        departments: department?.id,
      })

      setValue('attendant', null)

      setAttendants(() => data.results)
    } catch {
      notify.error('Não foi possível resgatar lista de departamentos.')
    }
  }

  const getConnectionsOptions = async () => {
    try {
      const { data } = await showConnectionsListSelect({
        page_size: 100,
        active: true,
      })

      if (data?.results?.length === 1) setValue('connection', data.results[0])

      setConnections(data.results)
    } catch {
      notify.error('Não foi possível resgatar listagem de conexões.')
    }
  }

  const getContactOptions = async (search) =>
    (await showContactsListSelect({ page_size: 100, name__icontains: search })).data.results

  const getDepartmentsOptions = async (search) =>
    (await showDepartmentsListSelect({ page_size: 100, active: true, name__icontains: search }))
      .data.results

  return (
    <Modal size='md' open={info} onClose={onClose} {...props}>
      <ModalHeader onClose={onClose}>
        <Typography variant='title' color='primary'>
          Iniciar nova conversa
        </Typography>
      </ModalHeader>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <Row width='100%' maxWidth='50%' gap='8px'>
            <Select
              label='Conexões'
              options={connections}
              keys={{ label: 'name', value: 'id' }}
              name='connection'
              control={control}
              error={errors?.connection}
              required
              isDisabled={connections?.length === 1}
            />
            <SelectAsync
              label='Contato'
              cacheOptions
              defaultOptions
              loadOptions={getContactOptions}
              keys={{ label: 'name', value: 'id' }}
              name='contact'
              control={control}
              error={errors?.contact}
              required
            />
          </Row>
          <Row mt='16px' width='50%' gap='8px'>
            <SelectAsync
              label='Departamento'
              cacheOptions
              defaultOptions
              loadOptions={getDepartmentsOptions}
              keys={{ label: 'name', value: 'id' }}
              name='department'
              control={control}
              error={errors?.department}
              required
            />
            <Select
              label='Atendente responsável'
              defaultOptions
              options={attendants}
              keys={{ label: 'name', value: 'id' }}
              name='attendant'
              control={control}
              error={errors?.attendant}
              required
            />
          </Row>
          <Row mt='16px' width='100%' gap='16px'>
            {watchConnection ? (
              <Column width='100%'>
                <PreviewTemplate
                  templatesList={templatesList}
                  control={control}
                  name='starter_template'
                  register={register}
                  watch={watch}
                  errors={errors}
                  setValue={setValue}
                />
              </Column>
            ) : (
              <Column width='100%' p='20px 0' alignItems='center' gap='16px'>
                <Image src={robotAlert} width='160px' />
                <Typography>Selecione uma conexão para mostrar sua lista de templates</Typography>
              </Column>
            )}
          </Row>
          {loading && (
            <Row p='8px 18px' alignItems='center' justifyContent='flex-end' gap='16px'>
              <Typography>Enviando</Typography>
              <Column>
                <Spinner size='sm' />
              </Column>
            </Row>
          )}
        </ModalBody>
        <ModalFooter>
          <Button maxWidth='100px' variant='text' color='grey.300' onClick={onClose}>
            Cancelar
          </Button>
          <Button
            type='submit'
            maxWidth='100px'
            color={loading ? 'grey.300' : 'primary'}
            disabled={loading}
          >
            Enviar
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}

export default ModalCreateTicket
