import styled from 'styled-components'

import { Column, Icon, Row, Typography } from 'design-system'
import RobotHelp from 'components/common/RobotHelp'

import { buttonsTemplateDict, colors } from 'configs'
import { formatMessageText, hexToRGBA } from 'helpers'

const TemplatePreviewCard = ({ template, message, ...props }) => {
  const { buttons, content, header, footer } = template || {}

  return template?.id ? (
    <Column
      background={hexToRGBA(colors.violet[100], 0.7)}
      borderRadius='8px'
      width={message ? '100%' : '280px'}
      {...props}
    >
      <Column p='4px 8px 0'>
        {header && (
          <Row>
            <Typography>{header?.text}</Typography>
          </Row>
        )}
        {content && (
          <Row mt='16px'>
            <Typography>{formatMessageText(content || '')}</Typography>
          </Row>
        )}
        {footer && (
          <Row mt='16px' width='100%' justifyContent='flex-end'>
            <Typography>{footer}</Typography>
          </Row>
        )}
      </Column>
      <ButtonsTemplate>
        {buttons?.length > 0 &&
          buttons.map((button) => (
            <Row gap='4px' justifyContent='center'>
              <Row
                width='90%'
                boxShadow='0px 2px 0px 0px #0000000D'
                background='#fff'
                borderRadius='8px'
                justifyContent='center'
                alignItems='center'
                mt='4px'
                p='8px'
                gap='2px'
              >
                <Icon icon={buttonsTemplateDict[button?.button_type]?.icon} color='grey.300' />
                <Typography>({button?.text})</Typography>
              </Row>
            </Row>
          ))}
      </ButtonsTemplate>
    </Column>
  ) : (
    <RobotHelp
      alert
      robot={false}
      color='#B35F1B'
      text='Selecione um template criado para visualizar uma prévia nesse espaço.'
    />
  )
}

const ButtonsTemplate = styled(Column)`
  padding-bottom: 16px;
  background: transparent;
  border-radius: 0 0 8px 8px;
`

export default TemplatePreviewCard
