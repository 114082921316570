import { Column, Modal, ModalBody, ModalFooter, ModalHeader, Typography } from 'design-system'

import Historic from './Historic'
import User from './User'
import UserRatingDetails from './UserRatingDetails'

const ModalRatingSurvey = ({ open, onClose, user }) => {
  return (
    <Modal open={open} size='lg' closeClickOut onClose={onClose}>
      <ModalHeader onClose={onClose}>
        <Column>
          <Typography variant='title' color='primary'>
            Dados do atendente
          </Typography>
        </Column>
      </ModalHeader>
      <ModalBody>
        <Column gap='16px'>
          <User user={user} />
          <UserRatingDetails user={user} />
          <Historic user={user} />
        </Column>
      </ModalBody>
      <ModalFooter></ModalFooter>
    </Modal>
  )
}

export default ModalRatingSurvey
