import { useEffect, useState } from 'react'

import { PageHeader } from 'components'
import { Card, Column, Datepicker, Row } from 'design-system'
import SatisfactionSurvey from './SatisfactionSurvey'
import { notify } from 'helpers'
import { dashboardTicketRatings } from 'services'
import { endOfMonth, startOfMonth } from 'date-fns'

const Dashboard = () => {
  const startMonth = startOfMonth(new Date())
  const endMonth = endOfMonth(new Date())

  const [ratings, setRatings] = useState([])
  const [loadingDash, setLoadingDash] = useState(true)
  const [dateRange, setDateRange] = useState([startMonth, endMonth])
  const [startDate, endDate] = dateRange

  const getDashboard = async (date) => {
    try {
      setLoadingDash(true)
      const { data } = await dashboardTicketRatings({
        closed_at__gte: date[0],
        closed_at__lte: date[1],
      })

      setRatings(data)
    } catch {
      notify.error('Não foi possível resgatar dados da dashboard')
    } finally {
      setLoadingDash(false)
    }
  }

  useEffect(() => {
    getDashboard(dateRange)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOnblur = () => getDashboard(dateRange)

  return (
    <Column p='24px' gap='24px'>
      <Card>
        <PageHeader title='Dashboard' description='Acompanhe os dados da sua empresa'>
          <Row>
            <Datepicker
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              onChange={(update) => setDateRange(update)}
              onBlur={handleOnblur}
              isClearable={true}
              placeholderText='Selecione o período'
            />
          </Row>
        </PageHeader>
        {/* <Row
          mt='24px'
          gap='16px'
          justifyContent='space-around'
          flexDirection={['column', 'column', 'row', 'row']}
        >
          <CardValue title='Tempo médio de resposta' value={`44s`} icon='Timer' />
          <CardValue title='Tempo médio de resposta' value={`44s`} icon='Timer' />
          <CardValue title='Tempo médio de resposta' value={`44s`} icon='Timer' />
        </Row> */}
        <SatisfactionSurvey ratings={ratings} loadingDash={loadingDash} />
      </Card>
    </Column>
  )
}

export default Dashboard
